import React, { useEffect, useState } from 'react';

const CustomizeStandPage = () => {
  const [occasionName, setOccasionName] = useState('');
  const [extraInfo, setExtraInfo] = useState('');

  return (
    <>
      <div className="add-driver-modal">
        <div style={{ display: 'flex', paddingBottom: 0, paddingTop: 50 }} className="form">
          <div className="input-wrapper">
            <label htmlFor="driver-name" className="input-label">
              اسم المناسبة<span className="red-color"> *</span>
            </label>
            <input
              id="driver-name"
              onChange={(e) => setOccasionName(e.target.value)}
              className="input"
              type="text"
              value={occasionName}
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor="driver-name" className="input-label">
              تفاصيل اخري<span className="red-color"> *</span>
            </label>
            <input
              id="driver-name"
              onChange={(e) => setExtraInfo(e.target.value)}
              className="input"
              type="text"
              value={extraInfo}
            />
          </div>
        </div>
      </div>
      <div className="greetings-container">
        <img
          className="invitee-background-img"
          src="https://imagecdn.prod.floward.com/web/files/tickets/0f7d6f17-4246-4303-8d03-c28c3890e81a.jpg"
        />
        <div className="greetings-page-wrapper">
          <div className="stand-family-name">{occasionName}</div>
          <div className="extra-info-name">{extraInfo}</div>
        </div>
      </div>
    </>
  );
};

export default CustomizeStandPage;
