import React, { useState, useEffect } from 'react';
import SectionLoader from '../components/shared/SectionLoader';
import { Grid, Card, Divider, Button } from '@material-ui/core';
import AppErrorBoundary from '../components/shared/AppErrorBoundary';
import Lightbox from 'react-image-lightbox';
import Rating from '@mui/material/Rating';
import AddBorchurDesignModal from '../components/modals/AddBorchurDesignModal';
import RemoveGalleryDesignModal from '../components/modals/RemoveGalleryDesignModal';
import { userType, designCategoryEnum } from '../constants/Helpers';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';
import CustomSelect from '../components/shared/CustomSelect';
// API
import AppService from '../services/AppService';

const GalleryPage = () => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [isImgOpen, setIsImgOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState('');
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState('');
  const [photoIndex, setPhotoIndex] = useState(0);
  const [selectedDesign, setSelectedDesign] = useState('');
  const [galleryData, setGalleryData] = useState([]);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [mostRateList, setMostRateList] = useState([]);
  const [params, setParams] = useState({
    category: -1,
  });

  const fetchGalleryData = async (params) => {
    setIsLoaderOpen(true);
    try {
      const res = await AppService.getFetchGalleryData(params);
      setGalleryData(res?.data);
      setIsLoaderOpen(false);
    } catch (err) {
      setIsLoaderOpen(false);
    }
  };

  useEffect(() => {
    fetchGalleryData(params);
  }, []);

  const handleCategoryChange = (category) => {
    setParams({ category });
    fetchGalleryData({ category });
  };

  const handleImageClick = (index) => {
    setIsImgOpen(true);
    setPhotoIndex(index);
  };

  useEffect(() => {
    setMostRateList(galleryData.filter((el) => el?.title >= 5));
    const interval = setInterval(() => {
      setSliderIndex(
        (prevIndex) => (prevIndex + 1) % galleryData.filter((el) => el?.title >= 5).length
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [galleryData]);

  return (
    <AppErrorBoundary>
      <div className="gallery-wrapper" style={{ backgroundColor: '#F7F6F2' }}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ alignSelf: 'start', margin: 15, textAlign: 'right' }}>
              <img
                className="header-ops-img"
                src={
                  'https://floward.imgix.net/web/files/tickets/8701f83f-b7f6-4b01-b9ac-38afdce5f521.png'
                }
                style={{ width: 80 }}
              />
            </div>
            {userType == 1 && (
              <div style={{ alignSelf: 'end', margin: 19 }}>
                <Button
                  color="primary"
                  style={{ backgroundColor: '#006782', color: 'white', margin: 3 }}
                  variant="contained"
                  size="small"
                  onClick={() => setIsModalOpen((prev) => !prev)}
                >
                  رفع التصميم
                </Button>
              </div>
            )}
          </div>
          {isLoaderOpen ? (
            <SectionLoader />
          ) : (
            <>
              {mostRateList.length > 0 && (
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                  <img
                    src={mostRateList[sliderIndex]?.image}
                    alt={mostRateList[sliderIndex]?.title}
                    style={{
                      width: '65%',
                      objectFit: 'cover',
                      borderRadius: '8px',
                      maxWidth: 300,
                    }}
                  />
                </div>
              )}
              <Divider orientation="horizontal" style={{ backgroundColor: '#806C00' }} />
              {/* <div className="input-wrapper" style={{ width: '50%', padding: 5, margin: '0 auto' }}>
                <CustomSelect
                  listData={[
                    { id: -1, name: 'الكل' },
                    { id: 0, name: 'كلاسيكي' },
                    { id: 1, name: 'مودرن' },
                  ]}
                  handleChange={(value) => handleCategoryChange(value?.id)}
                  placeholder="التصنيف"
                  selectedValue={params?.category}
                  optionValue="id"
                  optionName="name"
                  disableClearIcon
                />
              </div> */}
              <Grid container style={{ justifyContent: 'space-around', padding: '10px 7px' }}>
                {galleryData?.map((el, index) => (
                  <Grid
                    item
                    xs={4}
                    md={3}
                    spacing={1}
                    key={index}
                    style={{ textAlign: 'center', marginBottom: 20, position: 'relative' }}
                  >
                    <Card style={{ padding: 6, marginLeft: 5, backgroundColor: '#eee' }}>
                      <img
                        src={el.image}
                        alt={el.title}
                        style={{ width: '100%' }}
                        onClick={() => handleImageClick(index)}
                      />
                    </Card>
                    {/* <p className="categpry-title">{designCategoryEnum[el.category]}</p> */}
                    <Rating
                      style={{ direction: 'ltr', fontSize: '1.1rem' }}
                      name="read-only"
                      value={el.title}
                      readOnly
                    />
                    {userType == 1 && (
                      <>
                        <CreateIcon
                          onClick={() => {
                            setSelectedDesign(el);
                            setIsModalOpen(true);
                          }}
                          style={{
                            position: 'absolute',
                            top: 3,
                            right: 3,
                            fontSize: 18,
                            color: '#006782',
                          }}
                        />
                        <CancelIcon
                          onClick={() => {
                            setSelectedDesign(el);
                            setIsRemoveModalOpen(true);
                          }}
                          style={{
                            position: 'absolute',
                            top: 3,
                            left: 5,
                            fontSize: 18,
                            color: '#FF4433',
                          }}
                        />
                      </>
                    )}
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </div>
        <AppErrorBoundary>
          <p className="copy-rights">
            {' '}
            جميع الحقوق محفوظة لشركة دعوة لتنظيم المعارض و المؤتمرات و المعسكرات الرياضية ©{' '}
            {new Date().getFullYear()}
          </p>
        </AppErrorBoundary>
      </div>
      {isModalOpen && (
        <AddBorchurDesignModal
          isPopupOpen={isModalOpen}
          setIsPopUpOpen={setIsModalOpen}
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          fetchGalleryData={fetchGalleryData}
          params={params}
        />
      )}
      {isRemoveModalOpen && (
        <RemoveGalleryDesignModal
          isPopupOpen={isRemoveModalOpen}
          setIsPopUpOpen={setIsRemoveModalOpen}
          fetchGalleryData={fetchGalleryData}
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          params={params}
        />
      )}
      {isImgOpen && (
        <Lightbox
          mainSrc={galleryData[photoIndex].image}
          nextSrc={galleryData[(photoIndex + 1) % galleryData.length].image}
          prevSrc={galleryData[(photoIndex + galleryData.length - 1) % galleryData.length].image}
          onCloseRequest={() => setIsImgOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + galleryData.length - 1) % galleryData.length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % galleryData.length)}
        />
      )}
    </AppErrorBoundary>
  );
};
export default GalleryPage;
